@import url(https://fonts.googleapis.com/css?family=Inter:wght@400,500,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Inter:wght@400,500,700&display=swap);
* {
  font-family: montserrat; }

body {
  margin: 0;
  cursor: default; }

div {
  cursor: inherit; }

.icon-wrapper g g g g {
  fill: red !important;
}
.top-radius {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.bottom-radius {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.box-shadow {
  box-shadow: 0 2px 4px 0 rgba(226, 230, 236, 0.5);
}

.cta-yellow-btn {
  background: #FFBA00;
  color: #2F3033;
  font-size: 18px;
  font-weight: 700;
  display: flex;
}
.cta-yellow-btn:hover {
  background: #ffcf50;
}

.cta-disabled-btn {
  background: #E1E6EA;
  cursor: default !important;
  color: #FFFFFF !important;
  font-size: 18px;
}

.cta-blue-btn {
  font-weight: bold;
  color: #FFFFFF;
  background: #305cc6;
  display: flex;
}

.cta-green-btn {
  font-weight: bold;
  color: #FFFFFF !important;
  background: #58ba72;
  display: flex;
}

.cta-green-revert-btn {
  font-weight: bold;
  background: #FFFFFF !important;
  color: #58ba72 !important;
  border: 1px solid #58ba72 !important;
  display: flex;
}

.cta-blank-btn {
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(226, 230, 236, 0.5);
  background-color: #ffffff;
  border: 2px solid transparent;
}
.cta-blank-btn:hover {
  border: 2px solid #305cc6;
}

.cta-link {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #305cc6;
  text-decoration: underline;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.tooltip {
  width: 10em;
  padding: 1em;
  border-radius: 10px;
}

.header-container {
  background: white;
  display: none;
}

.signUp-container {
  width: 100%;
  overflow: hidden;
  display: flex;
}
.signUp-container .placeholder .welcome-header {
  width: 75%;
  font-size: 28px;
  font-weight: bold;
  color: #35373a;
  text-align: left;
  line-height: 1.4;
  position: absolute;
  top: 24%;
  left: 15%;
}
.signUp-container .placeholder .welcome-header .sub-header {
  font-size: 20px;
  font-weight: 500;
  margin-top: 1em;
  line-height: 1.5;
}
.signUp-container .placeholder .welcome-header .small-header {
  font-size: 28px;
  font-weight: 600;
}
.signUp-container .placeholder .quick-loan-text {
  width: 75%;
  font-size: 20px;
  font-weight: 600;
  color: #35373a;
  text-align: left;
  line-height: 1.4;
  position: absolute;
  top: 32%;
  left: 15%;
}
.signUp-container .placeholder .abaca-sponsorship {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  color: #FFFFFF;
}
.signUp-container .placeholder .thank-you-img-container {
  margin-top: 13%;
  margin-left: auto;
  margin-right: auto;
}
.signUp-container .placeholder .thank-you-img-container img {
  height: 100%;
  width: 100%;
}
.signUp-container .placeholder .abaca-logo {
  margin-top: 0.5em;
}
.signUp-container .placeholder .img-container {
  margin-top: auto;
  display: flex;
  flex-direction: row;
}
.signUp-container .placeholder .img-container img {
  display: block;
  width: auto;
  height: auto;
  margin: auto;
  max-height: 445px;
}
.signUp-container .placeholder .cancel-img-container {
  margin: 10em auto auto;
}
.signUp-container .sba-placeholder {
  background: #FFFFFF;
}
.signUp-container .form-container {
  overflow-y: overlay;
  overflow-x: hidden;
  background: #FFFFFF;
  width: 45%;
  margin: 0 auto;
}
.signUp-container .form-container .form-loader-container {
  height: 90%;
  display: flex;
}
.signUp-container .form-container .form-loader-container .abaca-loading-container {
  margin: auto;
  height: 5em;
}
.signUp-container .form-container .divider {
  width: 90%;
  margin: auto;
  height: 2px;
  border-top: 1px solid #dbdedf;
}
.signUp-container .form-container .security-icons {
  font-size: 13px;
  border-top: 1px solid #dbdedf;
  width: 90%;
  padding-top: 1em;
  text-align: center;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
}
.signUp-container .form-container .security-icons .icons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0.5em;
}
.signUp-container .form-container .security-icons .icons-container .security-text {
  margin-left: 1em;
}
.signUp-container .form-container .security-icons .icons-container .icons {
  margin-bottom: 0.5em;
}

.signUp-container-mobile {
  width: 100%;
  background: #F5FAFC;
  display: flex;
  flex-direction: column;
}
.signUp-container-mobile .form-container {
  background: #F5FAFC;
}

@media screen and (max-width: 420px) {
  .form-container {
    background: #F5FAFC;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
  }
  .form-container .modal-bg {
    z-index: 10;
    position: absolute;
    width: 100%;
    height: 93.3%;
    background: rgba(14, 32, 56, 0.8);
  }
  .form-container .form-loader-container {
    height: 90%;
    display: flex;
  }
  .security-icons {
    background: #F5FAFC;
    text-align: center;
    width: 85%;
    margin: auto auto 1em;
    font-size: 13px;
    padding-top: 1em;
  }
  .security-icons .icons-container {
    flex-direction: column;
    justify-content: center;
  }
  .security-icons .icons-container .security-text {
    margin-left: 1em;
  }
  .security-icons .icons-container .icons {
    margin-bottom: 0.5em;
  }
  .signUp-container-mobile .form-container {
    overflow-x: hidden;
    display: flex;
  }
  .signUp-container-mobile .form-container .security-icons {
    margin-bottom: 1em;
  }
}
@media screen and (max-width: 1260px), screen and (max-height: 550px) {
  .header-container {
    background: white;
    display: block;
    width: 100%;
  }
  .signUp-container .placeholder {
    display: none;
  }
  .signUp-container .form-container {
    width: 100%;
  }
}
@media screen and (max-height: 750px) {
  .img-container img {
    max-height: 20em !important;
  }
}

.top-radius {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.bottom-radius {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.box-shadow {
  box-shadow: 0 2px 4px 0 rgba(226, 230, 236, 0.5);
}

.cta-yellow-btn, .yellow {
  background: #FFBA00;
  color: #2F3033;
  font-size: 18px;
  font-weight: 700;
  display: flex;
}
.cta-yellow-btn:hover, .yellow:hover {
  background: #ffcf50;
}

.cta-disabled-btn, .disabled {
  background: #E1E6EA;
  cursor: default !important;
  color: #FFFFFF !important;
  font-size: 18px;
}

.cta-blue-btn, .blue {
  font-weight: bold;
  color: #FFFFFF;
  background: #305cc6;
  display: flex;
}

.cta-green-btn, .green {
  font-weight: bold;
  color: #FFFFFF !important;
  background: #58ba72;
  display: flex;
}

.cta-green-revert-btn, .green-revert {
  font-weight: bold;
  background: #FFFFFF !important;
  color: #58ba72 !important;
  border: 1px solid #58ba72 !important;
  display: flex;
}

.cta-blank-btn, .blank {
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(226, 230, 236, 0.5);
  background-color: #ffffff;
  border: 2px solid transparent;
}
.cta-blank-btn:hover, .blank:hover {
  border: 2px solid #305cc6;
}

.cta-link {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #305cc6;
  text-decoration: underline;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.tooltip {
  width: 10em;
  padding: 1em;
  border-radius: 10px;
}

.btn {
  color: #2F3033;
  font-weight: 700;
  height: 3em;
  font-size: 15px;
  border-radius: 10px;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  cursor: pointer;
  min-width: 7em;
  display: flex;
  vertical-align: middle;
}
.btn .btn-text {
  width: 100%;
  text-align: center;
  margin: auto;
}
.btn .icon-container {
  height: 60%;
  margin: auto 0.6em auto auto;
  border-left: 1px solid #E1E6EA;
  padding-left: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.blue {
  color: #FFFFFF !important;
}
.blue:hover {
  background: #234baa;
}

.calendar {
  padding: 0.1em 0;
  margin-top: 0.6em;
  border-radius: 10px;
  background: #FFFFFF;
  font-weight: 400;
  font-size: 14px;
  box-shadow: 0 2px 4px 0 rgba(226, 230, 236, 0.5);
}
.calendar .btn-text {
  text-align: left;
  margin-left: 1em;
}

.blank {
  height: 2.7em !important;
}

.selected {
  border: 2px solid #305cc6;
  color: #305cc6;
  font-weight: 700 !important;
}

.big {
  font-size: 18px;
}

.margin-top {
  margin-top: auto;
}

div#wave {
  display: flex;
  margin: 1.6em 0 auto 0.3em;
}
div#wave .dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-right: 3px;
  background: #2F3033;
  animation: wave 1.3s linear infinite;
}
div#wave .dot:nth-child(2) {
  animation-delay: -1.1s;
}
div#wave .dot:nth-child(3) {
  animation-delay: -0.9s;
}

@keyframes wave {
  0%, 60%, 100% {
    transform: initial;
  }
  30% {
    transform: translateY(-2px);
  }
}

.top-radius {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.bottom-radius {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.box-shadow {
  box-shadow: 0 2px 4px 0 rgba(226, 230, 236, 0.5);
}

.cta-yellow-btn {
  background: #FFBA00;
  color: #2F3033;
  font-size: 18px;
  font-weight: 700;
  display: flex;
}
.cta-yellow-btn:hover {
  background: #ffcf50;
}

.cta-disabled-btn {
  background: #E1E6EA;
  cursor: default !important;
  color: #FFFFFF !important;
  font-size: 18px;
}

.cta-blue-btn {
  font-weight: bold;
  color: #FFFFFF;
  background: #305cc6;
  display: flex;
}

.cta-green-btn {
  font-weight: bold;
  color: #FFFFFF !important;
  background: #58ba72;
  display: flex;
}

.cta-green-revert-btn {
  font-weight: bold;
  background: #FFFFFF !important;
  color: #58ba72 !important;
  border: 1px solid #58ba72 !important;
  display: flex;
}

.cta-blank-btn {
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(226, 230, 236, 0.5);
  background-color: #ffffff;
  border: 2px solid transparent;
}
.cta-blank-btn:hover {
  border: 2px solid #305cc6;
}

.cta-link {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #305cc6;
  text-decoration: underline;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.tooltip {
  width: 10em;
  padding: 1em;
  border-radius: 10px;
}

.upgrade-container .support-link {
  display: flex;
  flex-direction: row;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.62;
  color: #2F3033 !important;
  width: 90%;
  margin-bottom: 2em;
  margin-left: 3.1em;
}
.upgrade-container .support-link .mail-link a {
  margin-left: 0.3em;
  margin-bottom: 0.5em;
  font-weight: 600;
  text-decoration: underline;
  color: #2F3033;
}

.coverage-navigation .btn {
  margin-bottom: 1em;
}

.close-btn-navigation {
  position: absolute;
  top: 2%;
  right: 1%;
  cursor: pointer;
  z-index: 10;
  font-size: 10px;
  background: #F7F8F8;
  padding: 0.5em 2em;
  font-weight: 500;
  border-radius: 20px;
}

@media only screen and (max-width: 420px) {
  .support-link {
    margin-left: 2em !important;
    flex-direction: column !important;
  }
}
@media screen and (orientation: landscape) and (max-width: 768px) {
  .close-btn-navigation {
    position: absolute;
    top: -25%;
    right: -4%;
    cursor: pointer;
  }
}

.top-radius {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.bottom-radius {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.box-shadow {
  box-shadow: 0 2px 4px 0 rgba(226, 230, 236, 0.5);
}

.cta-yellow-btn {
  background: #FFBA00;
  color: #2F3033;
  font-size: 18px;
  font-weight: 700;
  display: flex;
}
.cta-yellow-btn:hover {
  background: #ffcf50;
}

.cta-disabled-btn {
  background: #E1E6EA;
  cursor: default !important;
  color: #FFFFFF !important;
  font-size: 18px;
}

.cta-blue-btn {
  font-weight: bold;
  color: #FFFFFF;
  background: #305cc6;
  display: flex;
}

.cta-green-btn {
  font-weight: bold;
  color: #FFFFFF !important;
  background: #58ba72;
  display: flex;
}

.cta-green-revert-btn {
  font-weight: bold;
  background: #FFFFFF !important;
  color: #58ba72 !important;
  border: 1px solid #58ba72 !important;
  display: flex;
}

.cta-blank-btn {
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(226, 230, 236, 0.5);
  background-color: #ffffff;
  border: 2px solid transparent;
}
.cta-blank-btn:hover {
  border: 2px solid #305cc6;
}

.cta-link {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #305cc6;
  text-decoration: underline;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.tooltip {
  width: 10em;
  padding: 1em;
  border-radius: 10px;
}

.form-layout {
  position: relative;
  width: 62%;
  height: 90vh;
  margin: 4em auto auto;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1366px) {
  .form-layout {
    height: 88vh;
  }
}
@media screen and (max-height: 750px) {
  .form-layout {
    height: 88vh;
  }
}
@media only screen and (max-width: 420px) {
  .form-layout {
    position: unset;
    width: 90%;
    margin: 0 auto;
    display: block;
  }
}


    .notification-bar-container{
        width: 100%;
        background-color: white;
    }


.root {
    width: 100%;
    flex-grow: 1;
    height: auto !important;
    margin-bottom: 8px;
}
.data-card-container{
    /*height: 84px;*/
    /*background-color: white;*/
}

/*.data-card-top-row{*/
/*    font-size: 9px;*/
/*}*/

/*.data-card-middle-row{*/
/*    font-size: 15px;*/
/*    font-weight: bold;*/
/*}*/

/*.data-card-bottom-row{*/
/*    font-size: 9px;*/
/*    font-weight: 600;*/
/*}*/

@keyframes skeleton-glow {
    0%    { opacity: 1; }
    50%   { opacity: 0.5; }
    100%  { opacity: 1; }
}

.skeleton {
    animation: skeleton-glow 1.5s ease-in-out 0.5s infinite;
}
* {
    font-family: Inter !important;
}

html, body {
    min-height: 100%;
    background-color: #f6f6f6;
    overflow-x: hidden;
    overflow-y: auto;
}

.grecaptcha-badge{
    z-index: 9999 !important;
}

input[type="checkbox"] {
    accent-color: #305CC5 !important; /* IE */ /* FF */ /* Safari and Chrome */ /* Opera */
    transform: scale(1.2);
}

input[type="checkbox"]:hover{
    cursor: pointer;
}

.session-expired-custom-text{
    width: auto;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    background-color: transparent;
    border: none;
    color: rgb(29, 34, 41);
    text-align: center;
    margin: 0px 0px 8px;
    padding: 0px;
    overflow: auto;
    white-space: normal;
    opacity: 1;
}


.notification-container{
    padding-left: 54px;
}

p.notification-text {
    color: #2e312e;
    font-size: 11px;
    font-weight: bold;
}

p.notification-icon {
    color: #2e312e;
    font-size: 9px;
    padding-right: 5px;
}
.button-hover-secondary:hover, .button-hover-secondary:focus {
    color: #14378C !important;
    border-color: #14378C !important;
}

.button-hover-primary:hover, .button-hover-primary:focus {
    background: #14378C !important;
}

.button-hover-third:hover, .button-hover-third:focus {
    color: #14378C !important;
}

@font-face {
    font-family: "SatoshiBold";
    src: local("SatoshiBold"),
    url(/static/media/Satoshi-Bold.50eee48d.ttf) format("truetype");
    font-weight: bold;
}

.salaryo-heading {
    font-family: SatoshiBold !important;
}

p{
    font-family: Inter !important;
}
.salaryo-form-container {
    background-color: white;
}

.salaryo-form-container input[type="text"],input[type="date"] ,input[type="number"] {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
    border: none !important;
    border-radius: 4px !important;
}


.salaryo-input-container{
    flex-direction: column;
    display: flex;
}

.errors-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.error{
    width: 100%;
    font-size: 12px;
    color: #B12907;
    text-align: left;
}


.select-option:hover {
    background-color: #EAEFF9 !important;
}

.salaryo-select-disabled, .salaryo-select-disabled > div{
    background-color: rgb(247, 248, 248) !important;
    cursor: not-allowed;
}



.auto-complete-container div[class*="-control"] {
    height: 100% !important;
    border-color: #E0E0E0 !important;
    border-style: none !important;
}

.auto-complete-container div[class*="-singleValue"] {
    font-size: 16px !important;
}

.auto-complete-container div[class*="-placeholder"] {
    display: none !important;
}

.auto-complete-container div[class*="-control"] > div:nth-child(2) {
    display: none !important;
}

.auto-complete-container > div {
    height: 46px !important;
}

.auto-complete-container input[type="text"] {
  border: none !important;
}

input:focus {
    outline: #2684ff auto 1px;
}



input:focus {
    outline: #2684ff auto 1px;
}


input:focus {
    outline: #2684ff auto 1px;
}

.salaryo-date-input input {
    padding-right: 12px !important;
}
.salaryo-file-upload:hover{
    background-color: #F7F8F8 !important;
}
div.linear-progress-container div[class*="MuiLinearProgress-dashedColor"] {
    background-image: radial-gradient(rgb(44, 66, 323) 0%, #EAEFF9 16%, transparent 42%) !important;
}

div.linear-progress-container div[class*="MuiLinearProgress-barColorPrimary"] {
    background-color: #305CC5 !important;
}

div.linear-progress-container div[class*="MuiLinearProgress-colorPrimary"] {
    background-color: #EAEFF9 !important;
}

div.linear-progress-container div[class*="MuiLinearProgress-root-"] {
    height: 8px !important;
}

.salaryo-linear-progress > div > div {
    border-radius: 4px !important;
}

.salaryo-linear-progress > div {
    border-radius: 4px !important;
}

.salaryo-linear-progress > div > div:nth-child(2) {
    background-color: #305CC5 !important;
}

.salaryo-linear-progress > div > div:nth-child(3) {
    background-color: #EAEFF9 !important;
}







.finish-lottie-container > div{
    height: inherit !important;
}

.defer-table-top-container {
    background-image: linear-gradient(to right, #B8B8B8 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 16px 1px;
    background-repeat: repeat-x;
}

.progress-bar-container, .progress-bar-mobile{
    background: linear-gradient(198.95deg, #2249A8 0%, #0C2766 100%) !important;
}

.progress-bar-phase{
    margin-bottom: 32px;
}

.phase-icon-wrapper{
    width: 32px;
    height: 32px;
    border: 1px solid white;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
@keyframes skeleton-glow {
    0%    { opacity: 1; }
    50%   { opacity: 0.5; }
    100%  { opacity: 1; }
}

.skeleton {
    animation: skeleton-glow 1s ease-in-out 0.5s infinite;
}
.salaryo-dropdown-option:hover{
    background-color: #EAEFF9 !important;
}

.salaryo-dropdown-option:last-child{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.hamburger-menu-slider {
    height: 100%;
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0px;
    overflow-x: hidden;
    transition: 0.5s;
}


html,body{
    background-color: white !important;
}
@font-face {
    font-family: "SatoshiBold";
    src: local("SatoshiBold"),
    url(/static/media/Satoshi-Bold.50eee48d.ttf) format("truetype");
    font-weight: bold;
}

.salaryo-formatted-number *{
    font-family: SatoshiBold !important;
}
.up-arrow {
    display: inline-block;
    position: relative;
    text-decoration: none;
    border-radius: 2px;
}

.up-arrow-mobile {
    display: inline-block;
    position: relative;
    text-decoration: none;
    border-radius: 2px;
}

.up-arrow,.uparrow-mobile:hover{
    cursor: pointer !important;
}

.up-arrow:after {
    content: '';
    display: block;
    position: absolute;
    left: 8px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-bottom-color: white;
}

.up-arrow-mobile:after {
    content: '';
    display: block;
    position: absolute;
    left: 113px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-bottom-color: white;
}



.loan-select-option:hover{
    background-color: #EAEFF9 !important;
}
.progress-bar-container, .progress-bar-mobile{
    background: linear-gradient(198.95deg, #2249A8 0%, #0C2766 100%) !important;
}

.progress-bar-phase{
    margin-bottom: 32px;
}

.phase-icon-wrapper{
    width: 32px;
    height: 32px;
    border: 1px solid white;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
